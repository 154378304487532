import { createContext, ReactNode, useEffect } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppSelector } from '../../hooks';
import { AuthState } from '@aws-amplify/ui-components';
import useUserEvent from '../../hooks/useUserEvent/useUserEvent';

type RouteProvideProps = {
  children: ReactNode;
};
/**
 * 画面遷移Wrapper
 *
 * 画面遷移の前に処理を実施する
 *
 * NOTE：
 * Shell.tsxよりも上の階層で動作する共通コンポーネントがなかったため、このファイルを追加
 */
const RouteWrapper = ({ children }: RouteProvideProps) => {
  const userState = useAppSelector((state) => state.user);
  const { sendUserEvent, sendUserEventByNotAuth } = useUserEvent();
  useEffect(() => {
    // FIXME stateとAUthのcurrentSessionで取得するのではどちらが最適か？
    if (userState.authState === AuthState.SignedIn && userState.user) {
      // FIXME アクティブユーザー更新の処理もhooksとかに分けたほうがいいかも？
      const username = userState.user['cognito:username'];
      requestServerApi<any>(() => serverApi.mobileAppControllerCreateActiveLearnerLog(username));
      sendUserEvent('page_view');
    } else {
      sendUserEventByNotAuth('page_view');
    }
  }, [userState]);

  return <>{children}</>;
};
export default RouteWrapper;
